import BIcon from "../../BIcon";

export default function AnnouncementTypeChoice({ announcement }) {
  return (
    <>
      <label htmlFor="type-list" className="block text-sm/6 font-medium text-gray-900">
        Type
      </label>
      <ul id="type-list" className="mt-2 grid w-full gap-6 md:grid-cols-3">
        <li>
          <input type="radio" name="type" id="type-option-alert" value="alert" className="absolute opacity-0 peer" required defaultChecked={announcement?.type == "alert"} />
          <label htmlFor="type-option-alert" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer --dark:hover:text-gray-300 --dark:border-gray-700 peer-checked:border-primary hover:text-gray-600 --dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 --dark:text-gray-400 --dark:bg-gray-800 --dark:hover:bg-gray-700">
            <div className="block">
              <div className="w-full text-lg font-semibold"><BIcon name="window" className={"me-2"} />Alert</div>
              <div className="w-full text-sm">Trigger an alert box that grabs the users' attention.</div>
            </div>
          </label>
        </li>
        <li>
          <input type="radio" name="type" id="type-option-banner" value="banner" className="absolute opacity-0 peer" defaultChecked={announcement?.type == "banner"}/>
          <label htmlFor="type-option-banner" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer --dark:hover:text-gray-300 --dark:border-gray-700 peer-checked:border-primary hover:text-gray-600 --dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 --dark:text-gray-400 --dark:bg-gray-800 --dark:hover:bg-gray-700">
            <div className="block">
              <div className="w-full text-lg font-semibold"><BIcon name="pc-horizontal" className={"me-2"} />Banner</div>
              <div className="w-full text-sm">A banner on the top or bottom of the page.</div>
            </div>
          </label>
        </li>
        <li>
          <input type="radio" name="type" id="type-option-toast" value="toast" className="absolute opacity-0 peer" defaultChecked={announcement?.type == "toast"}/>
          <label htmlFor="type-option-toast" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer --dark:hover:text-gray-300 --dark:border-gray-700 peer-checked:border-primary hover:text-gray-600 --dark:peer-checked:text-gray-300 peer-checked:text-gray-600 hover:bg-gray-50 --dark:text-gray-400 --dark:bg-gray-800 --dark:hover:bg-gray-700">
            <div className="block">
              <div className="w-full text-lg font-semibold"><BIcon name="chat-right-dots" className={"me-2"} />Toast</div>
              <div className="w-full text-sm">A friendly popup in the corner of the page.</div>
            </div>
          </label>
        </li>
      </ul>
    </>
  )
}
