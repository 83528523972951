import { Outlet } from "react-router-dom";
import GenericPage from "./GenericPage";
import { getLuminance } from "../../utils";
import BIcon from "../BIcon";
import { useState } from "react";

export default function AnnouncementPreview({ announcementObj, children }) {

  const [screen, setScreen] = useState("desktop")

  const activeClass = "text-white bg-primary hover:bg-primary-light"
  const inactiveClass = "border text-primary border-primary hover:border-primary-light"

  const screenSwitchDiv = (
    <div className="flex flex-row gap-2">
      <button onClick={() => setScreen("mobile")}
        className={`rounded-md shadow-sm ps-2.5 pe-3.5 py-2.5 text-sm ${screen == "mobile" ? activeClass : inactiveClass}`}>
        <BIcon name={screen == "mobile" ? "phone-fill" : "phone"} className={"me-1"} />Mobile
      </button>
      <button onClick={() => setScreen("desktop")}
        className={`rounded-md shadow-sm ps-2.5 pe-3.5 py-2.5 text-sm ${screen == "desktop" ? activeClass : inactiveClass}`}>
        <BIcon name={screen == "desktop" ? "laptop-fill" : "laptop"} className={"me-2"} />Desktop
      </button>
    </div>
  )

  return (
    <div className="flex flex-row gap-12">
      {children}
      <div className="border-l p-12 pt-0 flex-grow">
        <GenericPage side={screenSwitchDiv} className={"h-full max-h-[80vh] relative"} flex title={<span className="font-semibold">Preview</span>}>
          <div
            class="absolute mt-12 -z-10 inset-0 h-full w-full bg-white bg-[radial-gradient(#e5e7eb_1px,transparent_1px)] [background-size:16px_16px] [mask-image:radial-gradient(ellipse_50%_50%_at_50%_50%,#000_60%,transparent_100%)]"
          ></div>
          {announcementObj && (
            <div className="p-16 shadow-lg rounded-2xl max-w-xl" style={{
              backgroundColor: announcementObj.backgroundColor,
              color: announcementObj.textColor
            }}>
              <h2 className="text-3xl font-bold text-center mb-6">
                {announcementObj.title}
              </h2>
              <p className="text-center opacity-85 font-semibold mb-10">
                {announcementObj.description}
              </p>
              {/* <form className="flex flex-row">
                <input type="email"/>
                <button type="submit">{announcementObj.callToActionTitle}</button>
              </form> */}
              <div className="flex justify-center gap-4">
                {announcementObj.collectEmail && <input placeholder="email@example.com" className="text-black rounded-md" type="email" name="callToActionEmail" />}
                <button className="py-3 px-6 rounded-md" style={{
                  backgroundColor: announcementObj.accentColor,
                  color: getLuminance(announcementObj.accentColor) > 0.5 ? 'black' : 'white'
                }}>
                  {announcementObj.callToActionTitle} &rarr;
                </button>
              </div>
            </div>
          )}
        </GenericPage>
      </div>
    </div>
  )
}