import { Link, useNavigate } from "react-router-dom"
import BIcon from "../../../../components/BIcon"
import { useContext, useEffect, useRef, useState } from "react"
import { NewAnnouncementContext } from "../../../../providers/NewAnnouncementProvider"
import AnnouncementTypeChoice from "../../../../components/elements/announcement/AnnouncementTypeChoice"
import AnnouncementLinkField from "../../../../components/elements/announcement/AnnouncementLinkField"
import AnnouncementDescriptionField from "../../../../components/elements/announcement/AnnouncementDescriptionField"
import AnnouncementTitleField from "../../../../components/elements/announcement/AnnouncementTitleField"
import AnnouncementCallToActionTitleField from "../../../../components/elements/announcement/AnnouncementCallToActionTitleField"
import { formListener } from "../../../../utils"

export default function Page0({ }) {

    const { announcementObj, setPage0 } = useContext(NewAnnouncementContext)

    const navigate = useNavigate()

    const updatePage = form => {
        const formData = new FormData(form);
        const type = formData.get("type");
        const title = formData.get("title");
        const description = formData.get("description");
        const callToActionUrl = formData.get("callToActionUrl");
        const callToActionTitle = formData.get("callToActionTitle");

        setPage0({
            type,
            title,
            description,
            callToActionUrl,
            callToActionTitle
        });
    }

    const onSubmit = async e => {
        e.preventDefault();

        updatePage(e.target)
        navigate("../1");
    }

    const formRef = useRef(null)

    useEffect(formListener(formRef, updatePage), [])

    return (
        <form ref={formRef} onSubmit={onSubmit}>
            <div className="--border-b --border-gray-900/10 pb-12 max-w-xl">
                <h2 className="text-base/7 font-semibold text-gray-900">General</h2>
                {/* <p className="mt-1 text-sm/6 text-gray-600">
                    This information will be displayed publicly so be careful what you share.
                </p> */}
                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
                        <AnnouncementTypeChoice announcementObj={announcementObj} />
                    </div>
                    <div className="sm:col-span-4">
                        <AnnouncementTitleField announcementObj={announcementObj} />
                    </div>
                    <div className="col-span-full">
                        <AnnouncementDescriptionField announcementObj={announcementObj} />
                    </div>
                    <div className="sm:col-span-2">
                        <AnnouncementCallToActionTitleField announcementObj={announcementObj} />
                    </div>
                    <div className="sm:col-span-4">
                        <AnnouncementLinkField announcementObj={announcementObj} />
                    </div>
                </div>
            </div>
            <button type="submit" className="ms-auto text-white rounded-md shadow-sm px-3.5 py-2.5 text-sm bg-primary hover:bg-primary-light">Next &rarr;</button>
            {/* <Link to="../1">asdasddsa</Link> */}
        </form>
    )
}