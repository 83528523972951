import { useEffect, useRef, useState } from "react"
import BIcon from "./BIcon"
import LogoCloud from "./LogoCloud"

const features = [
  {
    name: 'Email Lists.',
    description: 'Instantly grow your email list by offering incentives, capturing leads, and driving conversions.',
    icon: "envelope-fill",
  },
  {
    name: 'E-commerce Sales.',
    description: 'Maximize sales with targeted promotions and timely alerts that drive action when it matters most.',
    icon: "cart4",
  },
  {
    name: 'Customer Engagement.',
    description: "Keep customers engaged with real-time updates on offers, maintenance, or important news. Boost loyalty and retention!",
    icon: "person-raised-hand",
  },
]

const LOREM = [
  "Event Reminder: Join the virtual meeting on Wednesday at 10 AM.",
  "Training Announcement: Register for the leadership development workshop next month.",
  "Newsletter Release: Latest edition of the company newsletter available on the portal.",
  "Wellness Program: Sign up for yoga sessions starting Tuesday.",
  "Product Launch: Get ready for the launch of our new product line next week!",
  "Social Event: Join the company picnic this Saturday. Family welcome.",
  "Customer Feedback: We value your opinion! Participate in our survey to help us improve.",
  "Flash Sale: Massive discounts for the next 48 hours! Don't miss the best deals.",
  "New Arrivals: Check out the latest products in our spring collection.",
  "Exclusive Offer: Loyalty members get 20% off their next purchase. Apply now.",
  "Limited Stock Alert: Few items left of our top product! Hurry and order.",
  "Cart Reminder: Items in your cart are selling fast. Complete your purchase before they're gone.",
  "Free Shipping: Spend over $50 and enjoy free shipping on domestic orders this weekend.",
  "Gift Vouchers: Buy vouchers for special occasions and let loved ones choose their favorites."
]

export default function Features1() {

  const [demoToasts, setDemoToasts] = useState([])

  const DemoToast = ({ x, y, title, className }) => {
    return (
      <div
        className={`rounded-md px-3 py-2 text-md text-white absolute shadow-md ${className}`}
        style={{ left: `${x}px`, top: `${y}px` }}
      >
        <a href="#features1">{title} &rarr;</a>
      </div>
    );
  }

  const createDemoToasts = () => {
    const maxToasts = 8; // Maximum number of demo toasts to display at any time
    let intervalId;

    const createToast = () => {
      const randomTitle = LOREM[Math.floor(Math.random() * LOREM.length)];
      const randomX = Math.floor(Math.random() * 300) - 100;
      const randomY = Math.floor(Math.random() * 500);

      setDemoToasts((prev) => {
        if (prev.length >= maxToasts) {
          // Stop creating new toasts if the max number is reached
          clearInterval(intervalId);
          return prev;
        }

        const colors = ["bg-red-500", "bg-green-500", "bg-blue-500", "bg-yellow-500", "bg-indigo-500", "bg-purple-500", "bg-pink-500"];
        const randomColor = colors[prev.length % colors.length];
        const newToast = { x: randomX, y: randomY, title: randomTitle, className: `${randomColor} fade-in` };
        // Remove the 'fade-in' class from all other toasts
        const updatedToasts = prev.map(toast => ({
          ...toast,
          className: toast.className?.split(" ")[0] // Remove 'fade-in' class
        }));

        return [...updatedToasts, newToast];
      });
    };

    intervalId = setInterval(createToast, 800);
  }

  const observerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          createDemoToasts();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,  // Adjust this threshold as needed. It represents the percentage of the target's visibility.
      }
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    // Cleanup function
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);

  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8" id="features1">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              {/* <h2 className="text-base/7 font-semibold text-primary">Notify faster</h2> */}
              <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                {/* Site-wide notices for all use cases. */}
                {/* Notices That Work for Every Use Case */}
                {/* Ensure your users never miss <span className="underline decoration-dashed decoration-primary">important stuff.</span> */}
                Are your visitors leaving without <span className="underline decoration-dashed decoration-primary">taking action</span>?
              </p>
              <p className="mt-6 text-lg/8 text-gray-600">
                {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque,
                iste dolor cupiditate blanditiis ratione. */}
                {/* {process.env.REACT_APP_SITE_NAME} makes announcements and notifications easier than ever, ensuring that important information is always just a click away for your users.
                Easily create and customize all types of notices from the dashboard. */}
                {/* {process.env.REACT_APP_SITE_NAME} is a no-code tool for site announcements and notifications, ensuring your messages are always seen by the right audience. Create and customize notices that inform, engage, and optionally, convert 🤑. */}
                {process.env.REACT_APP_SITE_NAME} is a no-code platform that helps you create stunning popups, banners, and notifications designed to engage, convert, and drive sales. 🤑

              </p>
              <dl ref={observerRef} className="mt-10 max-w-xl space-y-8 text-base/7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <BIcon center name={feature.icon} aria-hidden="true" className="absolute left-1 top-1 size-5 text-primary" />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="relative w-[48rem] sm:w-[57rem]">
            <img
              alt="Product screenshot"
              src="https://tailwindui.com/plus/img/component-images/dark-project-app-screenshot.png"
              width={2432}
              height={1442}
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            />
            {demoToasts.map((toast, index) => (
              <DemoToast key={index} x={toast.x} y={toast.y} title={toast.title} className={toast.className} />
            ))}
          </div>

        </div>
      </div>
      <div className="mt-20">
        <LogoCloud />
      </div>
    </div>
  )
}