import { Link } from "react-router-dom"
import BIcon from "../BIcon"
import { capitalizeFirstLetter } from "../../utils"

const tdClassName = "py-2"

const icon = { "banner": "pc-horizontal", "toast": "chat-right-dots", "alert": "window" }

export default function AnnouncementList({ announcements }) {

    const Entry = ({ announcement }) => {
        const link = `/app/announcements/${announcement.id}`
        const activeStatusClass = announcement.enabled ? "text-primary bg-primary-subtle font-medium" : "text-gray-600 bg-gray-100"
        const activeStatusText = announcement.enabled ? "Active" : "Hidden"
        return (
            <tr className="hover:bg-body-tertiary py-4 group">
                <td className={`${tdClassName} ps-2`}>
                    <input type="checkbox" className="form-checkbox rounded-md" />

                </td>
                <td className={`${tdClassName} text-gray-500 group-hover:text-black`}><Link to={link}>{announcement.title}</Link></td>
                <td className={`${tdClassName} pe-2 text-gray-700 text-sm`}><BIcon name={"envelope-plus"} className={"me-1"} />Collect</td>
                <td className={`${tdClassName} text-gray-500`}><span className={`${activeStatusClass} px-2 py-1 text-sm rounded-md`}>{activeStatusText}</span></td>
                <td className={`${tdClassName} text-gray-700 group/local`}><BIcon name={icon[announcement.type]} className={"me-1"} /><span className="text-gray-500 opacity-0 group-hover/local:opacity-100 text-sm">{capitalizeFirstLetter(announcement.type)}</span></td>
            </tr>
        )
    }

    return (
        <div className="flex flex-col">
            <table className="table-fixed">
                <thead className="border-b">
                    <tr>
                        <th className="text-start font-medium py-2 ps-2">
                            <input type="checkbox" className="form-checkbox rounded-md" />
                        </th>
                        <th className="text-start font-medium py-2">Title</th>
                        <th className="text-start font-medium py-2 pe-2">Action</th>
                        <th className="text-start font-medium py-2">Status</th>
                        <th className="text-start font-medium py-2">Type</th>
                    </tr>
                </thead>
                <tbody>
                    {announcements.map((announcement, index) => <Entry key={index} announcement={announcement} />)}
                </tbody>
            </table>
        </div>
    )
}