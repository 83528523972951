import { useContext, useMemo, useState } from "react";
import GenericPage from "../../../components/dashboard/GenericPage";
import { DashboardContext } from "../../../providers/DashboardProvider";
import { Link, useParams, useNavigate } from "react-router-dom";
import { tenantDeleteAnnouncement, tenantPutAnnouncement } from "../../../Api";
import BIcon from "../../../components/BIcon";
import { ApplicationContext } from "../../../providers/ApplicationProvider";
import AnnouncementPreview from "../../../components/dashboard/AnnouncementPreview";
import AnnouncementSettings from "../../../components/dashboard/AnnouncementSettings";

export default function AnnouncementPage({ }) {

  const navigate = useNavigate()

  const { displayErrorModal, displaySuccessModal } = useContext(ApplicationContext)
  const { selectedTenant, refreshTenant } = useContext(DashboardContext)

  const { id } = useParams()

  const selectedAnnouncement = selectedTenant?.announcements.find(x => x.id === id)

  const [valuesChanged, setValuesChanged] = useState(false)

  const [announcement, setAnnouncement] = useState(selectedAnnouncement)

  if (!selectedTenant) return <></>

  const handleSubmit = async newAnnouncementObj => {
    try {
      await tenantPutAnnouncement(selectedTenant.id, selectedAnnouncement.id, newAnnouncementObj)
      await refreshTenant()
      setValuesChanged(false)
      displaySuccessModal("Success!", "The notice has been updated.")
    }
    catch {
      displayErrorModal("Error when updating notice.")
    }
  }

  const onChange = newAnnouncementObj => {
    setValuesChanged(true)
    setAnnouncement(newAnnouncementObj)
  }

  const handleDelete = async e => {
    await tenantDeleteAnnouncement(selectedTenant.id, id)
    await refreshTenant()
    navigate("../", { replace: true })
  }

  return (
    <AnnouncementPreview announcementObj={announcement}>
      <GenericPage className="relative pb-20" title="Notice">
        <AnnouncementSettings announcementObj={announcement} onChange={onChange} handleSubmit={handleSubmit} />
        <div className="pt-4">
          <div className="sm:col-span-6 text-red-500">
            <Link className="" onClick={handleDelete}><BIcon name={"trash"} className={"me-1"} />Delete Notice</Link>
          </div>
        </div>
        {valuesChanged && <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
          <div className="pointer-events-auto flex items-center justify-between gap-x-2 bg-white border shadow-lg px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
            <p className="text-sm leading-6 pe-2">
              {/* <strong className="font-semibold">Save Changes</strong>
            <BIcon name={"dot"} /> */}
              <span>Values have been changed.</span>
            </p>
            <button onClick={() => refreshTenant()} className="text-primary rounded-md shadow-sm px-3.5 py-1.5 text-sm border border-primary hover:border-primary-light">Revert</button>
            <button type="submit" form="announcementSettingsForm" className="text-white rounded-md shadow-sm px-3.5 py-1.5 text-sm bg-primary hover:bg-primary-light">Save</button>
          </div>
        </div>}
      </GenericPage>
    </AnnouncementPreview>
  )
}