import { useLoaderData, useLocation } from "react-router-dom";
import GenericPage from "../../components/dashboard/GenericPage";
import { useContext, useRef } from "react";
import { DashboardContext } from "../../providers/DashboardProvider";
import BIcon from "../../components/BIcon";
import { ApplicationContext } from "../../providers/ApplicationProvider";

export default function OverviewPage({ }) {

    const { displayErrorModal, displaySuccessModal } = useContext(ApplicationContext)
    const { selectedTenant } = useContext(DashboardContext)

    const codeRef = useRef(null)

    if (!selectedTenant) return <></>

    const handleCopy = async e => {
        if (codeRef.current) {
            try {
                await navigator.clipboard.writeText(codeRef.current.innerText);
                // alert('Copied to clipboard!');
                displaySuccessModal("Copied to clipboard!", "Paste the code into the <head> section on your website.")
            } catch (err) {
                console.error('Failed to copy: ', err);
            }
        }
    }

    return (
        <GenericPage title={"Overview"}>
            {/* <h2 className="text-3xl font-bold mb-4">{selectedTenant.domain}</h2> */}
            {/* <div className="flex flex-row gap-2">
                <div className="bg-body-secondary rounded-xl p-4 px-6">
                    <h4 className="text-3xl font-bold mb-2">{selectedTenant.announcements.length}</h4>
                    <span className="text-secondary">
                        Active Announcements
                    </span>
                </div>
                <div className="bg-body-secondary rounded-xl p-4 px-6">
                    <h4 className="text-3xl font-bold mb-2">{selectedTenant.announcements.length}</h4>
                    <span className="text-secondary">
                        Active Announcements
                    </span>
                </div>
            </div> */}
            <p className="mb-1">Add this script to your website's <code>&lt;head&gt;</code>:</p>
            <pre className="border rounded-md text-sm flex flex-row justify-between items-center">
                <code ref={codeRef} className="p-4">
                    <span style={{ color: '#a626a4' }}>&lt;script</span> <span style={{ color: '#4078f2' }}>src</span>
                    <span style={{ color: '#e45649' }}>=</span><span style={{ color: '#50a14f' }}>"{window.location.origin}/dist.js?p={selectedTenant.pub}"</span><span style={{ color: '#a626a4' }}>&gt;&lt;/script&gt;</span>
                </code>
                <button onClick={handleCopy} className="border py-2 px-3 me-2 rounded-md text-xs hover:bg-gray-100">
                    <BIcon name={"clipboard"} className={"me-1"} />Copy
                </button>
            </pre>
        </GenericPage>
    )
}