import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { DashboardContext } from "./DashboardProvider"
import { Outlet } from "react-router-dom"

export const OnboardingContext = createContext({})

export default function OnboardingProvider({ }) {

    const [page0, setPage0] = useState({})
    const [page1, setPage1] = useState({})
    const [page2, setPage2] = useState({})

    const onboardingObj = useMemo(() => ({ ...page0, ...page1, ...page2 }), [page0, page1, page2])

    const clear = () => {
        setPage0({})
        setPage1({})
        setPage2({})
    }

    return (
        <OnboardingContext.Provider value={{
            onboardingObj,
            setPage0, setPage1, setPage2,
            clear
        }}>
            <Outlet />
        </OnboardingContext.Provider>
    )
}