export const API_URL = process.env.REACT_APP_API_URL ?? "http://localhost:3000"

const get = async (endpoint, extraHeaders) => {
    const res = await (await fetch(API_URL + endpoint, {
        credentials: "include",
        headers: extraHeaders,
        signal: AbortSignal.timeout(6000)
    })).json()

    if (!res.success) {
        console.log(res)
        throw res
    }
    else {
        return res
    }
}

const withBody = async (verb, endpoint, payload) => {
    const res = await (await fetch(API_URL + endpoint, {
        credentials: "include",
        method: verb,
        body: JSON.stringify(payload),
        headers: {
            "Content-Type": "application/json"
        }
    })).json()

    if (!res.success) {
        throw res
    }
    else {
        return res
    }
}

const post = async (endpoint, payload) => {
    return await withBody("post", endpoint, payload)
}

const put = async (endpoint, payload) => {
    return await withBody("put", endpoint, payload)
}

// user

export async function getUser() {
    return await get("/user")
}

export async function onboard({ timeZone }) {
    return await post("/user/onboard", { timeZone })
}

// tenants

export async function addTenant(domain) {
    return await post("/tenants/add", { domain })
}

export async function getTenant(id) {
    return await get(`/tenants/${id}`)
}

export async function tenantNewAnnouncement(id, announcementObj) {
    return await post(`/tenants/${id}/announcements/new`, { announcementObj })
}

export async function tenantGetAnnouncement(id, announcementId) {
    return await get(`/tenants/${id}/announcements/${announcementId}`)
}

export async function tenantPutAnnouncement(id, announcementId, announcementObj) {
    return await put(`/tenants/${id}/announcements/${announcementId}`, { announcementObj })
}

export async function tenantDeleteAnnouncement(id, announcementId) {
    return await post(`/tenants/${id}/announcements/${announcementId}/delete`)
}

export async function tenantGetEmailList(id) {
    return await get(`/tenants/${id}/emaillist`)
}

// auth

export async function login(email, password) {
    return await post("/auth/login", { email, password })
}

export async function logout() {
    return await post("/auth/logout", {})
}

export async function register(email, password) {
    return await post("/auth/register", { email, password })
}

export async function requestPasswordReset(email) {
    return await post("/auth/passwordreset/request", { email })
}

export async function doPasswordReset(email, token, newPass) {
    return await post("/auth/passwordreset/do", { email, token, newPass })
}

// export async function requestVerification() {
//     return await post("/auth/verification/request", {})
// }

// export async function doVerification(email, token) {
//     return await post("/auth/verification/do", { email, token })
// }

// stripe

export async function createCheckoutSession(plan, period) {
    window.location.href = `${API_URL}/create-checkout-session?plan=${plan}&period=${period}`
}

// waitlist

export async function joinWaitlist(email) {
    return await post(`/waitlist/join`, { email })
}

// magic

export async function magicVisit(url) {
    return await post(`/magic/visit`, { url })
}
