export default function AnnouncementTitleField({ announcementObj }) {
  return (
    <>
      <label htmlFor="title" className="block text-sm/6 font-medium text-gray-900">
        Title
      </label>
      <div className="mt-2">
        <input
          defaultValue={announcementObj?.title}
          required
          name="title"
          type="text"
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6"
        />
      </div>
      <p className="mt-3 text-sm/6 text-gray-600">The title will be displayed directly on the notice.</p>
    </>
  )
}