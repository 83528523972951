import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react"
import BIcon from './BIcon'

const faqs = [
  {
    question: "How can I sign up for the service?",
    answer:
      "You can sign up by clicking the 'Join Waitlist' button on the homepage or by visiting the Signup page on our website.",
  },
  {
    question: "Is there a free trial available?",
    answer:
      "Yes, we offer a 14-day free trial for all new users. You can access all the premium features during the trial period.",
  },
  {
    question: "What payment methods are accepted?",
    answer:
      "We accept all major credit cards, including Visa, MasterCard, and American Express. Additionally, payments through PayPal are also accepted.",
  },
  {
    question: "How do I contact support if I encounter an issue?",
    answer:
      "You can contact our support team via the 'Support' section on our website. We offer 24/7 customer support through chat and email.",
  },
  {
    question: "Can I cancel my subscription?",
    answer:
      "Yes, you can cancel your subscription at any time through the 'Account Settings' page on our platform. Your account will remain active until the end of the billing cycle.",
  },
  // More questions can be added as needed...
]

export default function FAQ() {
  return (
    <div className="bg-white" id="faq">
      <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:px-8 lg:py-40">
        <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
          <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as="div" className="pt-6">
                <dt>
                  <DisclosureButton className="group flex w-full items-start justify-between text-left text-gray-900">
                    <span className="text-base font-semibold leading-7">{faq.question}</span>
                    <span className="ml-6 flex h-7 items-center">
                      <BIcon name={"plus-lg"} aria-hidden="true" className="h-6 w-6 group-data-[open]:hidden" />
                      <BIcon name={"dash-lg"} aria-hidden="true" className="h-6 w-6 [.group:not([data-open])_&]:hidden" />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel as="dd" className="mt-2 pr-12">
                  <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
