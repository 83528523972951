import { createContext, useContext, useEffect, useState } from "react";
import GenericPage from "../../../../components/dashboard/GenericPage";
import { DashboardContext } from "../../../../providers/DashboardProvider";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import BIcon from "../../../../components/BIcon";
import { NewAnnouncementContext } from "../../../../providers/NewAnnouncementProvider";
import AnnouncementPreview from "../../../../components/dashboard/AnnouncementPreview";
import AnnouncementSettings from "../../../../components/dashboard/AnnouncementSettings";
import { tenantNewAnnouncement } from "../../../../Api";

export default function NewAnnouncementPage({ }) {

    // const { id } = useParams()

    const { selectedTenant, refreshTenant } = useContext(DashboardContext)

    const navigate = useNavigate()

    const [announcementObj, setAnnouncementObj] = useState({
        title: "Wait a moment! ✋",
        description: "Before you leave, explore how we can transform your business decisions into growth opportunities.",
        accentColor: "#e16540",
        textColor: "#d1d0cf",
        backgroundColor: "#1f1f1f",
        enabled: true,
        collectEmail: true,
    })

    const onChange = newAnnouncementObj => {
        console.log(newAnnouncementObj)
        setAnnouncementObj(newAnnouncementObj)
    }

    const handleSubmit = newAnnouncementObj => {
        tenantNewAnnouncement(selectedTenant.id, newAnnouncementObj).then(res => {
            refreshTenant().then(() => {
                // clear()
                navigate(`/app/announcements/${res.announcement.id}`)
            })
        })
    }

    return (
        <AnnouncementPreview announcementObj={announcementObj}>
            <GenericPage title={"New Notice"}>
                <div className="pt-4 max-w-xl">
                    <AnnouncementSettings announcementObj={announcementObj} onChange={onChange} handleSubmit={handleSubmit} isNew={true}/>
                </div>
                <button form="announcementSettingsForm" type="submit" className="ms-auto text-white rounded-md shadow-sm px-3.5 py-2.5 text-sm bg-primary hover:bg-primary-light">Next &rarr;</button>
            </GenericPage>
        </AnnouncementPreview>
    )
}