import { useContext } from "react";
import AnnouncementList from "../../../components/dashboard/AnnouncementList";
import GenericPage from "../../../components/dashboard/GenericPage";
import { DashboardContext } from "../../../providers/DashboardProvider";
import BIcon from "../../../components/BIcon";
import { Link, useNavigate } from "react-router-dom";
import { NewAnnouncementContext } from "../../../providers/NewAnnouncementProvider";

export default function AnnouncementsPage({ }) {

    const { selectedTenant } = useContext(DashboardContext)
    const {clear} = useContext(NewAnnouncementContext)

    const navigate = useNavigate()

    if (!selectedTenant) return <></>

    const toolbar = (
        <div className="flex flex-row gap-2">
            {/* <Link to={"new"} className="text-primary rounded-md shadow-sm ps-2.5 pe-3.5 py-2.5 text-sm border border-primary hover:border-primary-light"><BIcon name={"plus"} className={"me-1"} />New Notice</Link> */}
            <Link to={"new"} className="text-white rounded-md shadow-sm ps-2.5 pe-3.5 py-2.5 text-sm bg-primary hover:bg-primary-light"><BIcon name={"plus"} className={"me-1"} />New Notice</Link>
        </div>
    )

    return (
        <GenericPage title={"Notices"} side={toolbar} description="Manage your notices here.">
            <AnnouncementList announcements={selectedTenant.announcements} />
        </GenericPage>
    )
}