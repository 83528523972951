export default function GenericPage({ title, side, children, flex, className, ...props }) {
    return (
        <div className={`max-w-6xl ${className}`} {...props}>
            <div className="pb-4 flex flex-row justify-between">
                <div>
                    <h1 className="text-3xl font-bold">{title}</h1>
                </div>
                <div>
                    {side}
                </div>
            </div>
            <div className={flex ? "flex justify-center items-center h-full" : ""}>
                {children}
            </div>
        </div >
    )
}