import { Link, Navigate, replace, useNavigate } from "react-router-dom";
import Spinner from "../../../components/Spinner";
import moment from 'moment-timezone';
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../providers/AuthProvider";
import { sleep } from "../../../utils";
import { onboard } from "../../../Api";

const timeZoneOptions = moment.tz.names();

export default function OnboardingPage({ }) {
  const { user, isGuestUser, isFreeUser, refreshUser } = useContext(AuthContext)

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    await sleep(1000)

    const formData = new FormData(e.target)
    const timeZone = formData.get("timezone")

    try {
      await onboard({ timeZone })
      await refreshUser()
    }
    catch {
      
    }
    finally {
      setLoading(false)
    }
  }

  const defaultTz = moment.tz.guess()

  useEffect(() => {
    if (isGuestUser) {
      navigate("/signin", { replace: true })
    }
    else if(user && user.onboarded) {
      navigate("/app", { replace: true })
    }
  }, [user])

  if (!user) return <></>

  return (
    <>
      <div className="flex min-h-[100vh] flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        {/* <Link className="absolute top-8 text-xl me-1 text-primary hover:text-primary-light" onClick={() => window.history.back()}>&larr; Back</Link> */}
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img
            alt="Your Company"
            src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600"
            className=" h-10 w-auto"
          />
          <h2 className="mt-10 text-start text-2xl/9 font-bold tracking-tight text-gray-900">
            Welcome to {process.env.REACT_APP_SITE_NAME}!
          </h2>
          <p>
            We need a little more information from you to give you the best experience.
          </p>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form onSubmit={handleSubmit} action="#" method="POST" className="space-y-6">
            <div className="sm:col-span-3">
              <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900">
                Time Zone
              </label>
              <div className="mt-2">
                <select
                  // disabled={loading}
                  defaultValue={defaultTz}
                  id="timezone"
                  name="timezone"
                  autoComplete="timezone-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary sm:max-w-xs sm:text-sm/6"
                >
                  {/* <option>{moment.tz.guess()}</option> */}
                  {timeZoneOptions.map(timeZone => (
                    <option key={timeZone} value={timeZone}>
                      {timeZone}
                    </option>
                  ))}
                </select>
              </div>
              <p className="mt-3 text-sm/6 text-gray-600">All your future announcements will be created using this time zone.</p>
            </div>
            {/* <div>
              <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label htmlFor="password" className="block text-sm/6 font-medium text-gray-900">
                  Password
                </label>
                <div className="text-sm">
                  <a href="#" className="font-semibold text-primary hover:text-primary-light">
                    Forgot password?
                  </a>
                </div>
              </div>
              <div className="mt-2">
                <input
                  id="password"
                  name="password"
                  type="password"
                  required
                  autoComplete="current-password"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6"
                />
              </div>
            </div> */}
            <div>
              {message &&
                <div className="mb-2">
                  <span className="text-red-600">{message}</span>
                </div>
              }
              <button
                disabled={loading}
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-primary-light focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
              >
                Start! {loading && <Spinner className={"ms-2"} />}
              </button>
            </div>
          </form>

          {/* <p className="mt-10 text-center text-sm/6 text-gray-500">
            Don't have an account?{' '}
            <a href="#" className="font-semibold text-primary hover:text-primary-light">
              Sign Up
            </a>
          </p> */}
        </div>
      </div>
    </>
  )
}