import { useLoaderData } from "react-router-dom";
import { tenantGetEmailList } from "../../Api";
import EmailList from "../../components/dashboard/EmailList";
import GenericPage from "../../components/dashboard/GenericPage";
import { useContext, useEffect, useState } from "react";
import { DashboardContext } from "../../providers/DashboardProvider";

export default function ListPage({ }) {

  const { selectedTenant } = useContext(DashboardContext)

  const [emailList, setEmailList] = useState([])

  useEffect(() => {
    if(selectedTenant) {
      tenantGetEmailList(selectedTenant.id).then(res => {
        setEmailList(res.emailList)
      })
    }
  }, [selectedTenant])

  

  return (
    <GenericPage title={"List"} description={"A list of all collected email addresses."}>
      <EmailList emailList={emailList}></EmailList>
    </GenericPage>
  )
}