import { Link, useNavigate } from "react-router-dom"
import BIcon from "../../../../components/BIcon"
import { useContext, useEffect, useState } from "react"
import { NewAnnouncementContext } from "../../../../providers/NewAnnouncementProvider"
import AnnouncementTypeChoice from "../../../../components/elements/announcement/AnnouncementTypeChoice"
import AnnouncementLinkField from "../../../../components/elements/announcement/AnnouncementLinkField"
import { DashboardContext } from "../../../../providers/DashboardProvider"
import { ApplicationContext } from "../../../../providers/ApplicationProvider"
import Spinner from "../../../../components/Spinner"
import { magicVisit } from "../../../../Api"

export default function PageInit({ }) {

    const { displayGenericModal, displayErrorModal } = useContext(ApplicationContext)
    const { selectedTenant } = useContext(DashboardContext)
    const { announcementObj, setPage0 } = useContext(NewAnnouncementContext)

    const navigate = useNavigate()

    const onSubmit = async e => {
        e.preventDefault();

        const formData = new FormData(e.target);
        const url = formData.get("magicUrl");

        displayGenericModal({
            title: "Scanning Website", buttons: [{ title: "Cancel", onClick: () => { } }],
            children: <p className="text-sm text-gray-500">We are visiting {url}, please be patient, this could take a minute...</p>
        })

        try {
            const { popupObject } = await magicVisit(url)
            const { title, subtitle, call_to_action } = popupObject

            displayGenericModal(false)
            // navigate("")
            setPage0({
                type: "alert",
                title,
                description: subtitle,
                
                callToActionTitle: call_to_action
            })
            navigate("../0")
        }
        catch (err) {
            displayErrorModal(err.message || "Unknown error, we are sorry about this! You could still create the popup manually.")
        }
    }

    const onManual = async e => {
        e.preventDefault()

        navigate("../0")
    }

    return (
        <form onSubmit={onSubmit}>
            <div className="--border-b --border-gray-900/10 pb-12 max-w-xl">
                <h2 className="text-base/7 font-semibold text-gray-900">Magic Mode</h2>
                {/* <p className="mt-1 text-sm/6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p> */}
                <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                    <div className="col-span-full">
                        <label htmlFor="link" className="block text-sm/6 font-medium text-gray-900">
                            Site URL
                        </label>
                        <div className="mt-2">
                            <input
                                defaultValue={`https://${selectedTenant?.domain}`}
                                required
                                name="magicUrl"
                                type="url"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6"
                            />
                        </div>
                        <p className="mt-3 text-sm/6 text-gray-600"><BIcon name={"stars"} /> {process.env.REACT_APP_SITE_NAME} will design a popup, based on your site design and text content.</p>
                    </div>
                </div>
            </div>
            <button type="button" onClick={onManual} className="ms-auto text-primary rounded-md shadow-sm px-3.5 py-2.5 text-sm border border-primary hover:border-primary-light me-2">Manual Mode</button>
            <button type="submit" className="ms-auto text-white rounded-md shadow-sm px-3.5 py-2.5 text-sm bg-primary hover:bg-primary-light">Magic Mode &rarr;</button>
            {/* <Link to="../1">asdasddsa</Link> */}
        </form>
    )
}