import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { DashboardContext } from "./DashboardProvider"
import { Outlet } from "react-router-dom"


export const NewAnnouncementContext = createContext({})

export default function NewAnnouncementProvider({ }) {

    const { selectedTenant } = useContext(DashboardContext)

    // const { id } = useParams()

    const [page0, setPage0] = useState({
        title: "Wait a moment! ✋",
        description: "Before you leave, explore how we can transform your business decisions into growth opportunities."
    })
    const [page1, setPage1] = useState({
        accentColor: "#e16540",
        textColor: "#d1d0cf",
        backgroundColor: "#1f1f1f"
    })
    const [page2, setPage2] = useState({

    })

    const announcementObj = useMemo(() => ({ ...page0, ...page1, ...page2 }), [page0, page1, page2])

    const clear = () => {
        setPage0({})
        setPage1({})
        setPage2({})
    }

    return (
        <NewAnnouncementContext.Provider value={{
            announcementObj,
            setPage0, setPage1, setPage2,
            clear
        }}>
            <Outlet />
        </NewAnnouncementContext.Provider>
    )
}