import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { formListener } from "../../utils"
import { Description, Field, Label, Switch } from "@headlessui/react"
import AnnouncementLinkField from "../elements/announcement/AnnouncementLinkField"
import AnnouncementCallToActionTitleField from "../elements/announcement/AnnouncementCallToActionTitleField"
import ColorPicker from "../elements/ColorPicker"
import AnnouncementTitleField from "../elements/announcement/AnnouncementTitleField"
import AnnouncementDescriptionField from "../elements/announcement/AnnouncementDescriptionField"
import AnnouncementTypeChoice from "../elements/announcement/AnnouncementTypeChoice"

export default function AnnouncementSettings({ announcementObj, isNew, onChange, handleSubmit }) {

  const navigate = useNavigate()

  const [accentColor, setAccentColor] = useState(announcementObj?.accentColor)
  const [textColor, setTextColor] = useState(announcementObj?.textColor)
  const [backgroundColor, setBackgroundColor] = useState(announcementObj?.backgroundColor)

  const [enabled, setEnabled] = useState(announcementObj?.enabled)
  const [collectEmail, setCollectEmail] = useState(announcementObj?.collectEmail)

  const formRef = useRef(null)

  const getFormAnnouncementObj = form => {
    const formData = new FormData(form)

    const title = formData.get("title")
    const description = formData.get("description")
    const callToActionUrl = formData.get("callToActionUrl");
    const callToActionTitle = formData.get("callToActionTitle");

    const accentColor = formData.get("accentColor")
    const textColor = formData.get("textColor")
    const backgroundColor = formData.get("backgroundColor")

    const newAnnouncementObj = {
      id: announcementObj.id,
      type: announcementObj.type,
      enabled,

      title,
      description,

      callToActionUrl,
      callToActionTitle,
      collectEmail,

      accentColor,
      textColor,
      backgroundColor,

      path: "/",
      cooldownTime: 240
    }

    return newAnnouncementObj
  }

  useEffect(formListener(formRef, form => {
    onChange(getFormAnnouncementObj(form))
  }), [])

  useEffect(() => {
    const newAnnouncementObj = getFormAnnouncementObj(formRef.current)
    console.log(newAnnouncementObj, announcementObj)

    const areObjectsEqual = (obj1, obj2) => {
      const keys1 = Object.keys(obj1);
      const keys2 = Object.keys(obj2);

      if (keys1.length !== keys2.length) return false;

      for (let key of keys1) {
        if (obj1[key] !== obj2[key]) return false;
      }

      return true;
    }

    if (!areObjectsEqual(newAnnouncementObj, announcementObj)) {
      onChange(newAnnouncementObj);
    }
  }, [accentColor, textColor, backgroundColor, enabled, collectEmail]);

  if (!announcementObj) return <></>

  return (
    <div>
      {process.env.NODE_ENV == "development" && window.location.hash == "#debug" && <pre className="text-xs text-gray-500 whitespace-pre-wrap break-words overflow-hidden max-w-xl">
        {JSON.stringify(announcementObj)}
      </pre>}
      <form ref={formRef}
        onSubmit={e => {
          e.preventDefault()
          handleSubmit(getFormAnnouncementObj(e.target))
        }}
        id="announcementSettingsForm">
        <div className="border-b border-gray-900/10 pb-12 max-w-xl">
          <h2 className="text-base/7 font-semibold text-gray-900">General</h2>
          {/* <p className="mt-1 text-sm/6 text-gray-600">
                            This information will be displayed publicly so be careful what you share.
                        </p> */}
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {isNew && <div className="col-span-full">
              <AnnouncementTypeChoice announcementObj={announcementObj} />
            </div>}
            {!isNew && <div className="col-span-full">
              <Field className="flex items-center justify-between">
                <span className="flex flex-grow flex-col">
                  <Label as="span" passive className="text-sm font-medium leading-6 text-gray-900">
                    Enabled
                  </Label>
                  <Description as="span" className="text-sm text-gray-500">
                    Disabled notices will not be shown to users.
                  </Description>
                </span>
                <Switch
                  // defaultChecked={announcementObj}
                  checked={enabled}
                  onChange={setEnabled}
                  className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[checked]:bg-primary"
                >
                  <span
                    aria-hidden="true"
                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                  />
                </Switch>
              </Field>
            </div>}
            <div className="sm:col-span-4">
              <AnnouncementTitleField announcementObj={announcementObj} />
            </div>
            <div className="col-span-full">
              <AnnouncementDescriptionField announcementObj={announcementObj} />
            </div>
          </div>
        </div>
        <div className="border-b border-gray-900/10 pb-12 pt-10 max-w-xl">
          <h2 className="text-base/7 font-semibold text-gray-900">Call To Action (CTA)</h2>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-2">
              <AnnouncementCallToActionTitleField announcementObj={announcementObj} />
            </div>
            <div className="sm:col-span-4">
              <AnnouncementLinkField announcementObj={announcementObj} />
            </div>
            <div className="col-span-full">
              <Field className="flex items-center justify-between">
                <span className="flex flex-grow flex-col">
                  <Label as="span" passive className="text-sm font-medium leading-6 text-gray-900">
                    Collect Emails
                  </Label>
                  <Description as="span" className="text-sm text-gray-500">
                    Users may be asked to provide their email addresses.
                  </Description>
                </span>
                <Switch
                  // defaultChecked={announcementObj}
                  checked={collectEmail}
                  onChange={setCollectEmail}
                  className="group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 data-[checked]:bg-primary"
                >
                  <span
                    aria-hidden="true"
                    className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                  />
                </Switch>
              </Field>
            </div>
          </div>
        </div>
        <div className="border-b border-gray-900/10 pb-12 pt-10 max-w-xl">
          <h2 className="text-base/7 font-semibold text-gray-900">Appearance</h2>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full">
              <ColorPicker
                title={"Accent Color"}
                name={"accentColor"}
                nameHex={"accentColorHex"}
                value={accentColor}
                setValue={setAccentColor}
              />
            </div>
            <div className="col-span-3">
              <ColorPicker
                title={"Text Color"}
                name={"textColor"}
                nameHex={"textColorHex"}
                value={textColor}
                setValue={setTextColor}
              />
            </div>
            <div className="col-span-3">
              <ColorPicker
                title={"Background Color"}
                name={"backgroundColor"}
                nameHex={"backgroundColorHex"}
                value={backgroundColor}
                setValue={setBackgroundColor}
              />
            </div>
          </div>
        </div>
        {/* <div className="border-b border-gray-900/10 pb-12 pt-10 max-w-xl">
          <h2 className="text-base/7 font-semibold text-gray-900">Time</h2>
          <p className="mt-1 text-sm/6 text-gray-600">Choose when the notice goes live.</p>
          <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-3">
              <DateTimePicker title={"Start Time"} zeroName="Now" name={"startTime"} nameDate={"startTimeDate"} defaultValue={announcement.startTime} />
            </div>
            <div className="col-span-3">
              <DateTimePicker title={"End Time"} zeroName="Never" name={"endTime"} nameDate={"endTimeDate"} defaultValue={announcement.endTime} />
            </div>
          </div>
        </div> */}
      </form>
    </div>
  )
}