import BIcon from "./BIcon"

const features = [
  {
    name: 'Anti-AdBlock',
    description: 'We are notably not affected by ad-blockers, and take active measures against it, ensuring every user is reached.',
    icon: "ban",
  },
  {
    name: 'Toasts',
    description:
      'Subtle pop-ups for unobtrusive updates.',
    icon: "chat-right-dots",
  },
  {
    name: 'Alerts',
    description:
      'High-priority messages to ensure no critical updates are missed.',
    icon: "window",
  },
  {
    name: <span><BIcon name={"circle-fill"} className={"animate-pulse text-primary me-1"} />... all in real-time!</span>,
    description:
      'All notices can be updated in real-time, so your users are always in the know.',
    icon: "clock",
  },
]

export default function Features2New() {
  return (
    <div className="bg-white py-24 sm:py-32" id="features2">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          {/* <h2 className="text-base/7 font-semibold text-primary">Deploy faster</h2> */}
          <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-5xl lg:text-balance">
            {/* Everything you need to deploy your app */}
            {/* Customize and deliver notices of <span className="underline decoration-dashed decoration-primary">every kind</span>. */}
            Fully Automatic,<br/><span className="underline decoration-dashed decoration-primary">Notably So</span>.
          </p>
          <p className="mt-6 text-lg/8 text-gray-600">
            {/* Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum
            pulvinar et feugiat blandit at. In mi viverra elit nunc. */}
            {/* Experience the freedom of having all your notifications and alerts centralized. With effortless integration, you'll enhance user communication and satisfaction like never before. */}
            {/* Take control of your notifications with easy integration and an intuitive dashboard. From banners to alerts, deliver impactful messages that enhance user satisfaction. */}
            Experience the seamless automation with {process.env.REACT_APP_SITE_NAME}. Simply integrate our script, and watch as it manages automatic A/B testing, ensuring the best-performing popups are served to your users to maximize conversion and engagement effortlessly.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base/7 font-semibold text-gray-900">
                  <div className="absolute left-0 top-0 flex size-10 items-center justify-center rounded-lg bg-primary">
                    <BIcon center name={feature.icon} aria-hidden="true" className="size-6 text-white" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base/7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}