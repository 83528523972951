import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import { AuthContext } from "./AuthProvider";
import { getTenant } from "../Api";
import VerifyDomainModal from "../components/elements/modals/VerifyDomainModal";
import DomainSettingsModal from "../components/elements/modals/DomainSettingsModal";

export const DashboardContext = createContext({})

export const DashboardProvider = () => {

    const { user } = useContext(AuthContext)

    const [selectedTenantId, _setSelectedTenantId] = useState(null)
    const setSelectedTenantId = (tenantId) => {
        _setSelectedTenantId(tenantId)
        console.log("SET:", tenantId)
        window.localStorage.setItem("selectedTenantId", tenantId)
    }

    useEffect(() => {
        if (!selectedTenantId && user && user.tenants?.length > 0) {
            const savedSelectedTenantId = window.localStorage.getItem("selectedTenantId")
            console.log("SAVED:", savedSelectedTenantId)

            // Check if has a saved tenant id and it exists in current tenant list
            // If both conditions are true, auto-select that tenant
            if(savedSelectedTenantId && user.tenants.find(x => x.id == savedSelectedTenantId)) {
                setSelectedTenantId(savedSelectedTenantId)
            }
            else {
                setSelectedTenantId(user.tenants[0].id)
            }
        }
    }, [user])

    const [selectedTenant, setSelectedTenant] = useState(null)

    const refreshTenant = async () => {
        const res = await getTenant(selectedTenantId)
        setSelectedTenant(res.tenant)
    }

    // const [showVerifyDomainModal, setShowVerifyDomainModal] = useState(true)

    useEffect(() => {
        if (selectedTenantId) {
            refreshTenant()
        }
    }, [selectedTenantId])

    return (
        <DashboardContext.Provider value={{
            selectedTenant,
            setSelectedTenantId,
            refreshTenant
        }}>
            {/* <VerifyDomainModal show={showVerifyDomainModal} onClose={() => { }} /> */}
            <Outlet />
        </DashboardContext.Provider >
    );
};