export default function AnnouncementDescriptionField({ announcementObj }) {
  return (
    <>
      <label htmlFor="description" className="block text-sm/6 font-medium text-gray-900">
        Subtitle
      </label>
      <div className="mt-2">
        <textarea
          id="description"
          name="description"
          rows={3}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm/6"
          defaultValue={announcementObj?.description}
        />
      </div>
      <p className="mt-3 text-sm/6 text-gray-600">Write a subtitle here. Max a few sentences.</p>
    </>
  )
}